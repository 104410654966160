import React, { Suspense } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import { MasterRoute } from "./routes/MasterRoute";
//import LoginPage from "./views/Login";
import HttpsRedirect from "./utils/HttpsRedirect";

const hist = createBrowserHistory();

function App() {
  return (
    <Suspense fallback={<p></p>}>
      <ThemeProvider theme={mainTheme}>
        <HttpsRedirect>
          <Router history={hist}>
            <Switch>
                <Route path="/" component={MasterRoute} />;
            </Switch>
          </Router>
        </HttpsRedirect>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;

// const mainTheme = createTheme({});

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#F7E4CB", //sand
      contrastText: "#0A121A" //black
    },
    secondary: {
      main: "#4E6659" //green
    }
  }
});
