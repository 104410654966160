import { authProvider } from '../authProvider';
 
const FetchService = async (url, options) => {

const token = await authProvider.getAccessToken();
  if (token) {
    const jwtHeader = {Authorization: 'Bearer ' + token.accessToken};
    var mergedHeaders = { ...options.headers, ...jwtHeader }
    options.headers = mergedHeaders;
  }
  console.log("URL" + url);
 return fetch(url, options);
};

export default FetchService;