import React from 'react';
import CustomerIco from "@material-ui/icons/ViewList";
import Home from '../views/HomePage';
import HomeIco from "@material-ui/icons/Home";
//import Customers from '../views/Customers';
import Unauthorized from '../views/Unauthorized';
import LoginPopup from '../components/controls/LoginPopup';
import CustomComponent from '../components/CustomComponent';
import RafComponent from '../components/RafComponent';
import RafTelemetryComponent from '../components/RafTelemetryComponent';
import RafStatisticsComponent from '../components/RafStatisticsComponent';
import LogComponent from '../components/LogComponent';
import TelemetryLogComponent from '../components/TelemetryLogComponent';
import ImportButton from '../components/controls/ImportButton';

const indexRoutes = [

    {
        path: "/home",
        sidebarName: "Home page",
        navbarName: "Home",
        icon: HomeIco,
        component: Home,
        hasAuth: true
    },

    {
        path: "/qsorter",
        sidebarName: "QSORTER",
        navbarName: "QSorter - configuration",
        icon: CustomerIco,
        isContainer: true,
        subRoutesArray: [
            {
                path: "/qsorter/agent",
                sidebarName: "Agent",
                navbarName: "Qsorter - Agent configuration",
                icon: CustomerIco,
                component: () => <LoginPopup><ImportButton controlTag="qsorter.agent" /><CustomComponent controlTag="qsorter.agent" /></LoginPopup>,

                hasAuth: true
            },
            {
                path: "/qsorter/queue",
                sidebarName: "Queue",
                navbarName: "Queue configuration",
                icon: CustomerIco,
                component: () => <LoginPopup><ImportButton controlTag="qsorter.queue" /><CustomComponent controlTag="qsorter.queue"></CustomComponent></LoginPopup>,
                hasAuth: true
            },
        ]
    },
    {
        path: "/gtid",
        sidebarName: "GTID",
        navbarName: "GTID - configuration",
        icon: CustomerIco,
        isContainer: true,
        subRoutesArray: [
            {
                path: "/gtid/agency",
                sidebarName: "Agency",
                navbarName: "Agency configuration",
                icon: CustomerIco,
                component: () => <LoginPopup><CustomComponent controlTag="gtid.agency"></CustomComponent></LoginPopup>,
                hasAuth: true
            },
            {
                path: "/gtid/gtid",
                sidebarName: "GTID config",
                navbarName: "GTID configuration",
                icon: CustomerIco,
                component: () => <LoginPopup><CustomComponent controlTag="gtid.gtid"></CustomComponent></LoginPopup>,
                hasAuth: true
            },
        ]
    },
    {
        path: "/autocancel",
        sidebarName: "AUTO CANCEL",
        navbarName: "Autocancel - configuration",
        icon: CustomerIco,
        isContainer: true,
        subRoutesArray: [
            {
                path: "/autocancel/subscriber",
                sidebarName: "Subscriber",
                navbarName: "Autocancel - Subscriber configuration",
                icon: CustomerIco,
                component: () => <LoginPopup><CustomComponent controlTag="autocancel.subscriber"></CustomComponent></LoginPopup>,
                hasAuth: true
            },
            {
                path: "/autocancel/log",
                sidebarName: "Log",
                navbarName: "Autocancel Log",
                icon: CustomerIco,
                component: () => <LoginPopup><LogComponent controlTag="autocancel.log"></LogComponent></LoginPopup>,
                hasAuth: true
            },
            {
                path: "/autocancel/telemetrylog",
                sidebarName: "Exceptions",
                navbarName: "Autocancel - Exceptions",
                icon: CustomerIco,
                component: () => <LoginPopup><TelemetryLogComponent controlTag="autocancel.telemetry"></TelemetryLogComponent></LoginPopup>,
                hasAuth: true
            }
        ]
    },
    {
        path: "/raf",
        sidebarName: "RAF",
        navbarName: "Raf - configuration",
        icon: CustomerIco,
        isContainer: true,
        subRoutesArray: [
            {
                path: "/raf/statistics",
                sidebarName: "Statistics",
                navbarName: "Raf - Statistics",
                icon: CustomerIco,
                component: () => <LoginPopup><RafStatisticsComponent controlTag="raf.statistics" actionMethod="onActionMethod"></RafStatisticsComponent></LoginPopup>,
                hasAuth: true
            },
            {
                path: "/raf/telemetry",
                sidebarName: "Telemetry",
                navbarName: "Raf - Telemetry",
                icon: CustomerIco,
                component: () => <LoginPopup><RafTelemetryComponent controlTag="raf.telemetry" actionMethod="onActionMethod"></RafTelemetryComponent></LoginPopup>,
                hasAuth: true
            },
            {
                path: "/raf/consolidator",
                sidebarName: "Consolidator",
                navbarName: "Raf - Consolidator configuration",
                icon: CustomerIco,
                component: () => <LoginPopup><RafComponent controlTag="raf.consolidator" actionMethod="onActionMethod"></RafComponent></LoginPopup>,
                hasAuth: true
            }
        ]
    },
    {
        path: "/unauthorized",
        component: Unauthorized,
        navbarName: "Unauthorized",
        hasAuth: true
    },
    { redirect: true, path: "/", to: "/home", navbarName: "Redirect" }
];

export default indexRoutes;