import React from 'react';
import { Button, Tooltip} from "@atlas-design-system/react";  
import { Grid } from '@material-ui/core';
import { CustomComponentStructure } from '../../utils/CustomComponentStructure';
import FetchService from '../../utils/FetchService';
import Loading from './Loading'

class ImportButton extends React.Component {
    controlTag
    constructor(props) {
        super(props);
        this.project = props.controlTag.split(".")[0];
        this.entity = props.controlTag.split(".")[1];
        this.urlConfig = new CustomComponentStructure();
        this.dictionary = { PCC: "Pcc", Queue: "AgentQ", SON: "AgentSon", Name: "AgentName", SysQ: "SysQ", Category: "SortedCagegory" };
        this.state = { isLoading: false };
    }

    render() {
        return (<>
            <Grid container style={{ margin: '15px 0px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15' }}>
                    <Tooltip placement="right" content="Import data from .csv file">
                        <Button variant="primary"  component="label" style={{ verticalAlign: 'center' }}>
                            Import<input type="file" accept=".csv" style={{ display: "none" }} onChange={this.handleCapture} />
                        </Button>
                    </Tooltip>
                </div>
                {
                    this.state.isLoading ?
                        (
                            <div style={{position:'fixed', top:'50%', left:'50%', zIndex:99999999}}>
                                <Loading />
                            </div>
                        ) : <></>
                }

            </Grid>
        </>);
    }

    handleCapture = ({ target }) => {

        if (target.files.length === 1) {
            let reader = new FileReader();
            reader.onload = e => {
                this.SendFileToService(reader.result)
            }
            reader.readAsText(target.files[0]);
        }
    };

    SendFileToService(fileString) {
        try {
            this.setState({ isLoading: true });
            
            let rows = fileString.split("\r\n");

            let headers = rows[0].split(";");

            let jsonArray = [];

            for (let i = 1; i < rows.length; i++) {
                let columns = rows[i].split(";");
                let obj = {};
                for (let j = 0; j < headers.length; j++) {
                    obj[this.dictionary[headers[j]]] = columns[j];
                }
                jsonArray.push(obj);
            }

            let url = `${this.urlConfig.bulk}/${this.project}/${this.entity}`;
            //this.urlConfig.qsorter.agent.url.bulk + "/" + this.project + "/" + this.entity;
            //let url = new URL(this.urlConfig.qsorter.agent.url.bulk), params = {project:this.project, entity:this.entity}
            //Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

            FetchService(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify(jsonArray) })
                // .then(response => response.json())
                // .then(result => alert(result));        

                .then(response => { return { res: response.json(), success: response.ok } })
                .then(result => {
                    if (result.success)
                        alert("Selected file imported succesfully!");
                    else {
                        alert('There has been a problem with your import operation');
                        console.error('There has been a problem with your import operation: ', result.res);
                    }
                })

        }
        catch (ex) {
            alert("Something goes wrong!\r\n\r\n" + ex);
        }
        finally {
            this.setState({ isLoading: false });
        }
    }
}
export default ImportButton;