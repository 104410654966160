import React from "react";
//import { TravelportLogo } from "@atlas-design-system/react";
import { Header, Button, Typography, DropdownMenu, Tooltip } from "@atlas-design-system/react";
import TravelportSvg from '@atlas-design-system/core/dist/logos/travelport.svg';
import { MenuIcon, PersonIcon, ArrowLeftIcon, ArrowUpIcon, ArrowDownIcon, HomeIcon, MoreVerticalIcon, DocumentIcon } from "@atlas-design-system/react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import { authProvider } from "../../authProvider";
import indexRoutes from "../../routes/Index";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import menuAppbarStyles from "../../styles/MenuAppBar";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Collapse from '@material-ui/core/Collapse';

export default function MenuAppBar({ ...props }) {
  const classes = menuAppbarStyles();
  const theme = useTheme();
  const [openDrawer, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getPageName = () => {
    var indexItem = indexRoutes.find(a => a.path === props.location.pathname);
    if (indexItem)
      return indexItem.navbarName;
    else {
      var subRoute = indexRoutes.find(a => a.isContainer && a.subRoutesArray
        && a.subRoutesArray.some(s => s.path === props.location.pathname))
      if (subRoute) {
        subRoute = subRoute.subRoutesArray.find(a => a.path === props.location.pathname);
        return subRoute.navbarName;
      }
      return "";
    }
  };

  const activeRoute = routeName => {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const getNameOnly = userName => {
    let name = userName.substring(userName.indexOf(",") + 2);
    return name;
  };

  const onResize = (width, height) => {
    // console.log(`Width: ${width} and height: ${height}`)
    currentWidth = width;
    setOpen(width > 1200);
  };

  const currentPath = props.location.pathname;
  var slidebar = indexRoutes.find(a => a.subRoutesArray && a.subRoutesArray.some(s => s.path === currentPath))

  var menuItemsWithSubMenu = indexRoutes.filter(a => a.isContainer && a.subRoutesArray && a.subRoutesArray.length > 0);
  var initialMenuState = menuItemsWithSubMenu.map(a => {
    return {
      isExpanded: (slidebar && slidebar.sidebarName === a.sidebarName),
      sidebarName: a.sidebarName
    }
  });

  let [openCollapse, setOpenCollapse] = React.useState(initialMenuState);
  const handleClick = (name) => {
    var sidebar = openCollapse.find(a => a.sidebarName === name);
    sidebar.isExpanded = !sidebar.isExpanded;
    setOpenCollapse([...openCollapse]);
  };

  let currentWidth = window.innerWidth;

  return (
    <>
      <ReactResizeDetector handleWidth onResize={onResize} refreshMode="debounce" refreshRate={500} />
      <CssBaseline />
      <AppBar
        position="sticky"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer
        })}
      >
        <Header>
          <Button aria-label="open drawer"
            className="centerContent" onClick={handleDrawerOpen}>
            {!openDrawer ? <MenuIcon style={{ marginRight: 0, width: 56 }} /> : null}
          </Button>

          <Typography as="h3" variant="heroText" className="headerContent" bold>
            {useMediaQuery(theme.breakpoints.up("sm")) ? "Travelport Automation - " + getPageName() : ""}
          </Typography>

          <AzureAD provider={authProvider} forceLogin={false}>
            {({ login, logout, authenticationState, error, accountInfo }) => {
              return (
                <div className="headerAccount">
                  {authenticationState === AuthenticationState.Authenticated && (
                    <Typography
                      variant="heroText"
                      className={AuthenticationState.Authenticated ? "accountContent" : "hideDisplay"}
                      style={{ textalign: "right" }}
                    >
                      {'Welcome ' + getNameOnly(accountInfo.account.name) + '!'}
                    </Typography>
                  )}

                  <DropdownMenu
                    trigger={
                      (props) => (
                        <div {...props} style={{ margin: 5 }}>
                          <Button appearance="tertiary" icon={<PersonIcon />} />
                        </div>
                      )
                    }
                    placement="bottom-end"
                  >
                    {authenticationState === AuthenticationState.Unauthenticated
                      ? [
                        <DropdownMenu.Item key={0}
                          onClick={() => {
                            localStorage.setItem("loginClicked", true);
                            login();
                          }}
                        >
                          Login
                        </DropdownMenu.Item>
                      ]
                      : [
                        <DropdownMenu.Item key={0}
                          onClick={() => {
                            logout();
                          }}
                        >
                          Logout
                        </DropdownMenu.Item>,
                        <DropdownMenu.Item target="_blank" key={1}
                          href="https://portal.office.com/account/#personalinfo"
                        >
                          My account
                        </DropdownMenu.Item>
                      ]}
                  </DropdownMenu>
                </div>
              );
            }}
          </AzureAD>
        </Header>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer
          })
        }}
        open={openDrawer}
        style={{ margin: 5 }}
      >
        <AppBar position="sticky" className={clsx(classes.appBar)}>
          <Header className="centerContent">
            <div style={{ marginLeft: 15, marginTop: 5 }}>
              {/* <TravelportLogo width="180" Logo={() => null} /> */}
              <img src={TravelportSvg} width="180" alt="Travelport logo" />
            </div>
            <Button onClick={handleDrawerClose} style={{ marginLeft: 5 }}>
              <ArrowLeftIcon style={{ marginRight: 0 }} />
            </Button>
          </Header>
        </AppBar>
        <List>
          {indexRoutes
            .filter((a) => a.icon)
            .map((prop, key) => {
              if (prop.redirect) return null;

              if (prop.isContainer) {
                var isSubMenyExpanded = openCollapse.find(a => a.sidebarName === prop.sidebarName).isExpanded;
                return (
                  <React.Fragment key={"NAV_" + key}>
                    <ListItem button onClick={() => handleClick(prop.sidebarName)}>
                      <ListItemIcon>
                        <MoreVerticalIcon width="20" />
                      </ListItemIcon>
                      <ListItemText primary={prop.sidebarName} />
                      {isSubMenyExpanded ? <ArrowUpIcon width="20" /> : <ArrowDownIcon width="20" />}
                    </ListItem>

                    <Collapse key={"col_" + key} in={isSubMenyExpanded} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {prop.subRoutesArray.map((sublink, subkey) => (
                          <NavLink
                            onClick={(e) => {
                              e.preventDefault();
                              if (currentWidth < 1200) {
                                handleDrawerClose();
                              }
                              openCollapse.forEach(a => a.isExpanded = false);
                              var sidebar = openCollapse.find(a => a.sidebarName === prop.sidebarName);
                              sidebar.isExpanded = true;
                              setOpenCollapse([...openCollapse]);
                              props.history.push({
                                pathname: sublink.path,
                                //state: [...openCollapse]
                              });
                            }}
                            to={sublink.path}
                            activeClassName="active"
                            key={"nav_" + key + "_" + subkey}
                            className={classes.item}
                          >

                            <ListItem
                              button
                              className={clsx(classes.itemLink, {
                                [classes.activeItemLink]: activeRoute(sublink.path)
                              })}
                            >
                              <Tooltip content={sublink.navbarName} placement="bottom-end">
                                <ListItemIcon className={clsx(classes.itemIcon, {
                                  [classes.nested]: openDrawer
                                })}>
                                  <DocumentIcon width="20" />
                                </ListItemIcon>
                              </Tooltip>
                              <ListItemText primary={sublink.sidebarName} className={classes.subItemText} />
                            </ListItem>
                          </NavLink>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              }
              return (
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentWidth < 1200) {
                      handleDrawerClose();
                    }
                    props.history.push(prop.path);
                  }}
                  to={prop.path}
                  activeClassName="active"
                  key={"NAV_" + key}
                  className={classes.item}
                >
                  <ListItem
                    key={"li_" + key}
                    button
                    className={clsx(classes.itemLink, {
                      [classes.activeItemLink]: activeRoute(prop.path)
                    })}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      <HomeIcon width="20" />
                    </ListItemIcon>
                    <ListItemText primary={prop.sidebarName} className={classes.itemText} />
                  </ListItem>
                </NavLink>
              );
            })}
        </List>
      </Drawer>
      <div className={clsx({
        [classes.contentMarginDrawerOpen]: openDrawer,
        [classes.contentMargin]: !openDrawer
      })}
      >
        {props.children}
      </div>
    </>
  );
}
