import React from "react";
import "./LoaderStyle.css";
import PropTypes from "prop-types";

function Loading({ ...props }) {
  const { width, height, className, label, inTable } = props;
  var lab = null;
  if (label !== null) {
    lab = <p className="inner">{label}</p>;
  }
  return (
    <div style={{ margin: "0 auto" }}>
      <div
        style={{ width: width, height: height }}
        className={className + (inTable ? " tableInner" : " inner")}
      />
      {lab}
    </div>
  );
}

Loading.defaultProps = {
  className: "center",
  label: null,
  inTable: false
};

Loading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  label: PropTypes.string,
  inTable: PropTypes.bool
};

export default Loading;
