import React, { useEffect } from "react";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../authProvider";
import {
  Button,
  Card,
  makeStyles,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loading from "../components/controls/Loading";

export default function Unauthorized({ ...props }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      opacity: 1,
      height: "100%",
      outline: 0,
      transition: theme.transitions.create("opacity", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    card: {
      flexGrow: 1,
      maxWidth: 700,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 20,
      marginBottom: 20,
      padding: 20,
      position: "relative",
      maxHeight: "calc(100% - 64px)",
      overflowY: "auto"
    },
    loginButton: {
      marginLeft: "auto"
    }
  }));
  const classes = useStyles();
  let isAuthorizedUser = false;
  useEffect(() => {
    if (isAuthorizedUser) {
      props.history.push({ pathname: "/home" });
    }
  });
  return (
    <AzureAD provider={authProvider} forceLogin={false}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        if (authenticationState === AuthenticationState.Unauthenticated) {
          return (
            <div className={classes.root}>
              <Card className={classes.card}>
                Sorry, you are unauthorized. Please contact:{" "}
                <a href="mailto:SolutionsDevelopersEurope@travelport.com">
                  SolutionsDevelopersEurope@travelport.com
                </a>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <p>You can try login again (Travelport AD SSO):</p>
                  <Button variant="contained" color="primary" className={classes.loginButton} onClick={login}>
                    Login
                  </Button>
                </div>
                <br />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Click here to view errors:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {error && error.errorMessage
                        //&& error.errorMessage.includes("is not assigned to a role for the application") &&
                        && error.errorMessage}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Card>
            </div>
          );
        } else if (authenticationState === AuthenticationState.Authenticated) {
          isAuthorizedUser = true;
          return null;
        } else {
          return (
            <div style={{ marginTop: 30 }}>
              <Loading />
            </div>
          );
        }
      }}
    </AzureAD>
  );
}
