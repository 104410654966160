import React from 'react';
import {Spinner, Input, FormControl, Form, ButtonGroup, Button} from "@atlas-design-system/react"
import {SaveIcon} from "@atlas-design-system/react"
import FetchService from '../../utils/FetchService';

class RafGwsDialog extends React.Component
{
  controls
    constructor(props)
    {
        super(props);
        this.onSaveRequested = this.onSaveRequested.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.controls = ["gwsHap","gwsUrl","gwsPassword", "password","gwsUserName"];
        /*
        this.controls = [
          {name:"gwsHap",type: "text"},
          {name: "gwsUrl", type: "text"},
          {name: "gwsPassword", type: "password"},
          {name:"gwsUrl", type: "text"}
        ];
        */

        this.state = {
            userName : props.userName,
            uniqueId: '',
            gwsHap: '',
            gwsUrl: '',
            gwsUserName:'',
            createdDate : '',
            gwsPassword : '',
            urlHolder : props.urlHolder,
            error_gwsHap:'',
            error_gwsUrl: '',
            error_gwsUserName:'',
            error_gwsPassword : '',
            showSpinner: true,
        };        
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});      
    }

    onSaveRequested(event)
    {
       
        event.preventDefault();
       
       
        let errorMessage = 'Required.';
        let noErrorMessage = '';
        let hasError = false;
        this.controls.forEach(control=> {
          if (this.state[control] === "")
            {this.setState({['error_' + control]: errorMessage})  
          hasError = true;}

          else
          this.setState({['error_' + control]: noErrorMessage})  
        } );

          if(hasError)
          return;

        let url = this.state.urlHolder.hostAccess + "/" + this.props.userName;
        
        let bodyObject = {
            userName : this.props.userName,
            uniqueId: this.state.uniqueId,
            gwsUserName: this.state.gwsUserName,
            gwsPassword: this.state.gwsPassword,
            gwsHap: this.state.gwsHap,
            gwsUrl: this.state.gwsUrl          
        };

        var options = {
            method: 'POST',
            header : {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body : JSON.stringify(bodyObject)
            };
            
         FetchService(url, options).catch((error) => alert(error));
       
        this.props.closeModalRequested();
    }
    //fetch
    //save

    componentDidMount()
    {
      this.setState ( {
        showSpinner: true
      });
        this.closeModalRequested = this.props.closeModalRequested;
        let url = this.state.urlHolder.hostAccess + "/" + this.props.userName;
        
        
        var options = {
            method: 'GET',
            header : {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }

           
          FetchService(url, options)
          .then(response => response.json())
          .then(result => {
            this.setState ( {
                uniqueId: result.uniqueId,
                gwsHap: result.gwsHap,
                gwsUrl: result.gwsUrl,
                gwsUserName: result.gwsUserName,
                createdDate : result.createdDate,
                gwsPassword : result.gwsPassword,
                showSpinner:false
          });            
          }) 
          
    }

    render()
    {
        return<div className="sb-form-container">
           {this.state.showSpinner ? 
           <Spinner
                  primaryLabel="Loading the chart..."
                  size="medium"
                />
              :
<div className="sb-form-wrapper">
          <Form
            formHeader=""
            formSubtitle=""
            id="formGWS"
          > 
          <FormControl
              disabled
              id="form-consolidator-username"
              label="User name">
              <Input
                id="input-consolidator-username"
                value={this.props.userName}
              />    
              </FormControl>
            
            <FormControl
                error={this.state.error_gwsHap}
                id="form-gws-hap"
                label="GWS HAP"
            >
              <Input
              required
              value={this.state.gwsHap}
              id="input-gws-hap"
              name="gwsHap"
              onChange={this.handleChange}
              />
            </FormControl>
            <FormControl
             error={this.state.error_gwsUserName}
              required
              id="form-gws-user"
              label="GWS User Name"
            >
              <Input
              required={true}
                value={this.state.gwsUserName}
                name="gwsUserName"
                id="input-gwsUserName"
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl
            error={this.state.error_gwsPassword}
              required
              id="form-gws-password"
              label="GWS User Password"
            >
              <Input
              required={true}
              name="gwsPassword"
              value={this.state.gwsPassword}
              onChange={this.handleChange}
              type="password"
              id="input-gws-password"
              />              
            </FormControl>
            
            <FormControl
              required
              error={this.state.error_gwsUrl}
              id="form-gws-url"
              label="GWS Url"
            >
              <Input
              required={true}
                name="gwsUrl"
                value={this.state.gwsUrl}
                onChange={this.handleChange}
                id="input-gws-url"
              />
            </FormControl>
             <ButtonGroup>
                <Button type="submit" appearance="primary" onClick={this.onSaveRequested} icon={<SaveIcon />}>Save</Button>
                <Button onClick={this.props.closeModalRequested}>Cancel</Button>
             </ButtonGroup>
          </Form>
        </div>
              }
        
      </div>
    }
}

export default RafGwsDialog;