import React, { Fragment } from 'react'
import { CustomComponentStructure } from '../utils/CustomComponentStructure';
import  CustomMaterialTable  from './controls/CustomMaterialTable';
import {Modal} from "@atlas-design-system/react";  
import RafGwsDialog from '../components/controls/RafGwsDialog'
class RafComponent extends React.Component
{
    controlTag ;
    componentStructure;
    tableColumns;
    urlHolder;
    editable;
    actions;
    pageSize;    
    closeModalRequested = () =>   this.handleOpenCloseModal();

    handleOpenCloseModal = () =>   this.setState ( {hidden: !this.state.hidden});
    handleSaveButton = () => alert("Save button clicked");
    constructor(props)
    {
        super(props);
        this.state = {hidden: true, rowData: {}};
        this.tableColumns = [];
       
        this.componentStructure = new CustomComponentStructure();

        const project = props.controlTag.split(".")[0];
        const entity = props.controlTag.split(".")[1];
        const structure = this.componentStructure[project][entity];
        this.tableColumns = structure.tableColumns;
        this.urlHolder = structure.url;
        this.editable = structure.editable;
        this.pageSize=structure.pageSize;
        this.noDelete = true;

        this.actions= [
            {
              icon: 'settingsapplicationstwotoneicon',
              tooltip: 'Configure Host access/GWS configuration',
              onClick: (event, rowData)  =>  
                {
                    this.handleOpenCloseModal()
                    this.setState({rowData: rowData}) 
                }
            },
          ];
    }
    
    componentDidUpdate(prevProps)
    {
    }
    componentDidMount()
    {
    }
    render()
    {
        return <Fragment> 
        <Modal
            hidden={this.state.hidden}
            id="rafModal"
            size='medium'
            onDismiss = {this.handleOpenCloseModal}
            title="Consolidator - GWS configuration" 
            //actionButtons= {<ButtonGroup align="right"><Button appearance="primary" onClick={this.handleSaveButton}>Save</Button><Button onClick={this.handleOpenCloseModal}>Cancel</Button></ButtonGroup>}
            children={<RafGwsDialog 
                urlHolder= {this.urlHolder}
                userName={this.state.rowData.userName} 
                closeModalRequested={this.closeModalRequested}></RafGwsDialog>}>
           
        </Modal>
        <CustomMaterialTable 
            searchParameter={this.props.searchParameter}
            urlHolder={this.urlHolder} 
            resetSearchedState = {this.props.resetSearchedState} 
            editable= {this.editable} 
            noUpdate={false}
            noDelete = {true}
            searched = {this.props.searched} 
            controlTag={this.props.controlTag}
            tableTitle={this.props.title}
            tableColumns={this.tableColumns}
            actions={this.actions}
            pageSize = {this.pageSize} />
        </Fragment>
    }
}
export default RafComponent;