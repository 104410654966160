import React, { Component } from "react";
import CustomComponent from "./CustomComponent";
import { Button, Spinner, Dialog, FormControl } from "@atlas-design-system/react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Chart from 'chart.js/auto'; //required for chart.js
import { Bar } from "react-chartjs-2";
import FetchService from '../utils/FetchService';
import monthNames from "../utils/monthNames";
import DateFnsUtils from '@date-io/date-fns'; //this library of specific version 1.3.13 is required

class TelemetryLogComponent extends Component {
  state = {
    searched: false,
    searchParameter: [],
    telemetryData: {},
    xScaleDates: [],
    xScaleDatesNumeric: [],
    isChartLoading: false,
    dialogHidden: true,
    searchDate: new Date() //initial value cannot be undefined to avoid the "choose the component to be controlled or uncontrolled" error
  };

  resetSearchedState = () => {
    this.setState({ searched: false });
  }

  setDateFilter(date) {
    //let element = document.querySelector('[aria-label="filter data by DateAddedUtc"]');
    //element.value = date;
    //element.setAttribute("value", date);

    this.setState({
      searchParameter: [
        {
          field: "date",
          value: date
        }
      ],
      searched: true,
      searchDate: date
    });
  }

  searchDatepickerHandler(date) {
    this.setState({
      searchDate: date
    });
  }

  disablePastDates(date) {
    return date < new Date().setMonth(new Date().getMonth() - 1);
  }

  searchDateButtonClicked() {
    this.setState({
      searchParameter: [
        {
          field: "date",
          value: this.dateToStringFormat(this.state.searchDate)
        }
      ],
      searched: true
    });
  }

  changeXScale(days) {
    let daysArr = [];

    //days param received here is > 28 it means we want to substract 1 month from the current date
    if (days <= 28) {
      for (let i = 0; i < days; i++) {
        daysArr.push(new Date().setDate(new Date().getDate() - i));
      }
    } else {
      const finalDate = new Date().setMonth(new Date().getMonth() - 1);
      let i = 0;
      while (finalDate < new Date().setDate(new Date().getDate() - i)) {
        daysArr.push(new Date().setDate(new Date().getDate() - i));
        i++;
      }
    }
    
    //console.log(daysArr);
    //[1650021933309, 1649935533309, 1649849133309, 1649762733309, 1649676333309, 1649589933309, 1649503533309]

    let xScaleArr = daysArr.map(option => monthNames[new Date(option).getMonth()] + " " + new Date(option).getDate()).reverse();
    let xScaleArrNumeric = daysArr.map(option => this.dateToStringFormat(option)).reverse();

    this.setState({ 
      xScaleDates: [...xScaleArr],
      xScaleArrNumeric: [...xScaleArrNumeric]
    });
  }

  dateToStringFormat(date) {
    //to format yyyy-MM-dd
    let monthNumber = new Date(date).getMonth() + 1;
    let dateNumber = new Date(date).getDate();

    return new Date(date).getFullYear().toString() + "-" +
      (monthNumber < 10 ? "0" + monthNumber : monthNumber).toString() + "-" +
      (dateNumber < 10 ? "0" + dateNumber : dateNumber).toString();
  }

  fetchLogs() {
    this.setState({
      isChartLoading: true,
    });

    let telemetryApiUrl = process.env.REACT_APP_SERVICE_BASE_URL + "telemetry/autocancel/telemetrylog/0/max";
    let options = {
      method: 'POST',
      headers: {
        'Accept-Encoding': 'gzip'
      }
    }

    FetchService(telemetryApiUrl, options)
      .then(response => response.json())
      .then(response => this.setState({
        telemetryData: response,
        dialogHidden: !response.showWarning
      }, () => this.setState({
        isChartLoading: false
      })))
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.fetchLogs();
    this.changeXScale(7);
  }

  handleDialogHiddenChange() {
    this.setState(prev => { return { dialogHidden: !prev.dialogHidden } });
  }

  render() {

    const chartColor = "#081118";

    let counters = this.state.telemetryData.data && this.state.telemetryData.data
      .map(option => monthNames[new Date(option.dateAddedUtc).getMonth()] + " " + new Date(option.dateAddedUtc).getDate()).reverse()
      .reduce((acc, item) => {
        acc[item] = (acc[item] || 0) + 1;
        return acc;
      }, {});

    const yScale = this.state.xScaleDates && this.state.xScaleDates.map(date => counters && counters[date]);
    const colorsObject = this.state.xScaleDates && this.state.xScaleDates.map(() => chartColor);
    
    const chartData = {
      labels: this.state.xScaleDates && this.state.xScaleDates,
      datasets: [{
          label: '# of exceptions',
          data: yScale,
          backgroundColor: colorsObject,
          borderColor: colorsObject,
          maxBarThickness: 50
      }]
    };

    const chartOptions = {
      maintainAspectRatio: false,
      onClick: (evt, ele) => { 
        //console.log(this.state.xScaleArrNumeric[ele[0].element.$context.index]);
        ele[0] && this.setDateFilter(this.state.xScaleArrNumeric[ele[0].element.$context.index]);
      },
      scales: {
        x: {
          grid: {
            display: false
          },
        },
        y: {
          beginAtZero: true,
          suggestedMax: 5,
          grid: {
            display: false
          },
          ticks: {
            stepSize: 1
          }
        }
      }
    }

    const bar = <Bar data={chartData} options={chartOptions} />

    return (
      <div>
        <Dialog
            className="dialog"
            hidden={this.state.dialogHidden}
            appearance="warning"
            id="sbDialog"
            onDismiss={() => this.handleDialogHiddenChange()}
        >
          The number of the exceptions displayed on the chart has reached the maximum possible limit in the API settings. This data may not reflect the actual data. Please contact the administrator. 
        </Dialog>
        <div className="chart">
          {this.state.isChartLoading ?
              <div className="spinner">
                <Spinner
                  primaryLabel="Loading the chart..."
                  size="medium"
                />
              </div>
            : bar
          }
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.changeXScale(7)}
            style={{ "alignSelf": "flex-end", height: 37 }}
          >
            1 Week
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.changeXScale(14)}
            style={{ "alignSelf": "flex-end", height: 37 }}
          >
            2 Weeks
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.changeXScale(30)}
            style={{ "alignSelf": "flex-end", height: 37 }}
          >
            1 Month
          </Button>
        </div>
        <div className="datepicker">
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl className="formDatePicker" label="">
                <KeyboardDatePicker  
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  id="sb-date-picker"
                  format="yyyy-MM-dd"
                  onChange={date => this.searchDatepickerHandler(date)}
                  onAccept={date => this.searchDatepickerHandler(date)}
                  value={this.state.searchDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputProps={{ style: { border: 0, width: 90 } }}
                  helperText=""
                  error={false}
                  disableFuture
                  shouldDisableDate={date => this.disablePastDates(date)}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.searchDateButtonClicked()}
              style={{ "alignSelf": "flex-end", height: 37 }}
            >
              Search
            </Button>
          </div>
        </div>
        <h3>Logs</h3>
        <CustomComponent
          resetSearchedState={this.resetSearchedState}
          searched={this.state.searched}
          searchParameter={this.state.searchParameter}
          controlTag={this.props.controlTag}
        ></CustomComponent>
      </div>
    );
  }
}
export default TelemetryLogComponent;
