import React, { Fragment } from 'react';
import MaterialTable from 'material-table';
import FetchService from '../../utils/FetchService';
import PatchedPagination from '../../utils/PatchedPagination';
import { DebounceInput } from 'react-debounce-input';

class CustomMaterialTable extends React.Component {
    urlHolder;
    tableTitle;
    header;
    editable;
    tableRef;
    searchParameter;
    actions;
    pageSize;

    componentDidUpdate(prevProps, prevState) {
        if (this.props.searched) {
            this.searchParameter = this.props.searchParameter;
            this.tableRef.current && this.tableRef.current.onQueryChange();
            this.props.resetSearchedState();
        }
    }

    constructor(props) {
        super(props);
        this.pageSize = 25;
        this.applyDebouncedLogic(props.tableColumns);

        if (this.props.pageSize)
            this.pageSize = this.props.pageSize;


        this.tableRef = React.createRef();
        this.header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        this.searchParameter = undefined;

        if (props.searchParameter)
            this.searchParameter = props.searchParameter;

        this.actions = props.actions;

        this.urlHolder = props.urlHolder;

        this.editable = {
            onRowAdd: newData =>
                new Promise((resolve, reject) => {
                    var options = {
                        method: 'POST',
                        header: this.header,
                        body: JSON.stringify(newData)
                    }
                    FetchService(this.urlHolder.admin, options)
                        .then((response) => {
                            if (response.ok) {
                                resolve();
                                return response.json();
                            }
                            alert("An error occured while saving data.");
                            reject();
                        });
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    resolve();
                }),
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    const dataUpdate = [null];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    var options = {
                        method: 'PUT',
                        header: this.header,
                        body: JSON.stringify(
                            {
                                original: oldData,
                                updated: newData
                            }
                        )
                    }
                    FetchService(this.urlHolder.admin, options)
                        .then((response) => {
                            if (response.ok) {

                                resolve();
                                return response;
                            }
                            alert("An error occured while saving data.");
                            reject();
                        });

                    //resolve();
                })
            , onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    var options = {
                        method: 'DELETE',
                        header: this.header,
                        body: JSON.stringify(oldData)
                    }
                    FetchService(this.urlHolder.admin, options);
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    resolve();
                })
        }
        if (props.noUpdate)
            this.editable.onRowUpdate = null;
        if (props.noDelete)
            this.editable.onRowDelete = null;

        if (props.editable === false)
            this.editable = {};
    }

    applyDebouncedLogic(tableColumns) {
        tableColumns.forEach(column => {
            if (column.debounced) {
                column.filterComponent = ({ columnDef, onFilterChanged }) => (
                    <div className="MuiFormControl-root MuiTextField-root">
                        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                            <div className="MuiInputAdornment-root MuiInputAdornment-positionStart"><span class="material-icons MuiIcon-root" aria-hidden="true">filter_list</span></div>
                            <DebounceInput
                                className='MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputTypeSearch MuiInput-inputTypeSearch'
                                debounceTimeout={500}
                                onChange={event => onFilterChanged(columnDef.tableData.id, event.target.value)}
                                placeholder={'Filter ' + column.title} />
                        </div>
                    </div>
                );
            }
        });
    }

    render() {
        return (
            <Fragment>
                <MaterialTable
                    components={{
                        Pagination: PatchedPagination,
                    }}


                    options={{
                        filtering: true,
                        search: false,
                        showTitle: false,
                        sorting: true,
                        pageSize: this.pageSize,
                        pageSizeOptions: [5, 10, 25, 100, 500, 1000]
                    }}


                    tableRef={this.tableRef}
                    editable={
                        this.editable
                    }

                    actions={this.actions}

                    title={this.props.tableTitle}
                    columns={this.props.tableColumns}
                    data={
                        query =>
                            new Promise((resolve, reject) => {
                                var searchFilters = [];
                                var orderBy = {};
                                if (query.filters && query.filters.length > 0) {
                                    query.filters.map((item) => searchFilters.push({ column: item.column.field, value: item.value }));
                                    console.log(query);
                                }

                                if (this.searchParameter && this.searchParameter.length > 0) 
                                    query.filters.map((item) => searchFilters.push({ column: item.field, value: item.value }));

                                if (query.orderBy) 
                                    orderBy = { column: query.orderBy.field, direction: query.orderDirection };
                                
                                let requestFilters = {
                                    filters: searchFilters,
                                    sort: orderBy,
                                    customSearchParameter: this.searchParameter
                                };

                                var options = {
                                    method: 'POST',
                                    header: this.header,
                                    body: JSON.stringify(requestFilters)
                                }

                                let url = this.urlHolder.search + "/" + (query.page + 1) + "/" + query.pageSize
                                FetchService(url, options)
                                    .then(response => response.json())
                                    .then(result => {
                                        resolve(
                                            {
                                                data: result.data,
                                                totalCount: result.totalCount,
                                                page: result.page - 1,
                                            })
                                    })

                            })
                    }></MaterialTable>

            </Fragment>
        )
    }
}

export default CustomMaterialTable;