
export class CustomComponentStructure  {
    gtid;
    qsorter;
    autocancel;
    baseUrl;
    raf;
    raftelemetry;

    constructor(actionMethod)
    {
        this.baseUrl = process.env.REACT_APP_SERVICE_BASE_URL;
        //console.log(this.baseUrl);
        //this.baseUrl = "https://guitravelportautomation.azurewebsites.net/api/";
        this.raf =
        {
            consolidator :
            {
                url:
                {
                    search: this.baseUrl + "raf/search/consolidator/getall",
                    admin: this.baseUrl + "raf/admin/consolidator",                    
                    hostAccess: this.baseUrl + "raf/admin/hostaccess",                    
                }, 
                tableColumns : [
                    { title: 'Unique id', field: 'uniqueId', editable: 'never', filtering: false },
                    { title: 'User name', field: 'userName', editable: 'onAdd', validate: rowData => rowData.userName !== '', debounced: true },
                    { title: 'Password', field: 'password', filtering: false,validate: rowData => rowData.password !== '' },
                    { title: 'Pcc', field: 'pcc',validate: rowData => rowData.pcc !== '', debounced: true },
                    { title: 'Default currency', field: 'defaultCurrency',validate: rowData => rowData.defaultCurrency !== '', debounced: true },
                    { title: 'Created date', field: 'createdDate', editable: 'never', filtering: false},
                    { title: 'Description', field: 'description',validate: rowData => rowData.description !== '', debounced: true },
                    { title: 'Active', field: 'isActive',  type: 'boolean' },
                  ],
                  editable: true,
                  pageSize:15,
            },
            telemetry:
            {
                url: {
                    search: this.baseUrl + "telemetry/raf/telemetry",
                },
                tableColumns:
                    [
                        { title: 'Pcc', field: 'pcc' },
                        { title: 'DateAddedUtc', field: 'dateAddedUtc', filtering: false },
                        { title: 'ExceptionMessage', field: 'exceptionMessage', filtering: false },
                    ],
                editable: false
            },
            statistics:
            {
                url: {
                    search: this.baseUrl + "telemetry/raf/statistics",
                },
                tableColumns:
                [
                    { title: 'DateAddedUtc', field: 'dateAddedUtc', filtering: false },
                    { title: 'ClientId', field: 'clientId' },
                    { title: 'Pcc', field: 'pcc' },
                    { title: 'Version', field: 'version' },
                ],
                editable: false
            }
        }

        this.autocancel =
        {
            log :
            {                
                url : {
                    search: this.baseUrl + "search/autocancel/log",
                    admin: this.baseUrl + "autocancel/log",
                },
                tableColumns :
                [
                    {title: 'Pcc', field: 'pcc'},
                    {title: 'PNR', field: 'pnr'},
                    {title: 'Start date/time', field: 'processingStartDateTime', filtering: false},
                    {title: 'End date/time', field: 'processingEndDateTime', filtering: false},
                    {title: 'Processing Type', field : 'processingType'},
                    {title: 'IsSuccessful', field: 'cancelOrSuccess', filtering: false}
                ],
                editable: false
            },
            subscriber: 
            {
                url : {
                    search: this.baseUrl + "search/autocancel/subscriber",
                    admin: this.baseUrl + "autocancel/subscriber"
                },
                tableColumns: 
                [
                    { title : 'Pcc', field: 'pcc', editable: 'onAdd'},
                    { title : 'Agency time zone city', field: 'agencyTimeZoneCity'},
                    { title : 'Queue for killed Pnrs', field: 'queueForKilledPnrs'},
                    { title : 'Agency', field: 'agencyNickName'},
                    { title : 'Avg. Pnr per day', field: 'averagePnrsPerDay', filtering: false },
                   { title : 'Specific SON', field: 'specificSon1', filtering: false },
                   { title : 'Cancel with no XX for specific SON', field: 'canxWithNoXxForSpecSons', filtering: false, type: 'boolean' },
                   { title : 'Cancel by Time', field: 'useSpecificTimeCanx', filtering: false, type: 'boolean' },
                   { title : 'Cancel No Show', field: 'useNoShowControl', filtering: false, type: 'boolean' },
                   { title : 'Log Cancelled Pnrs', field: 'logCancelledPnrs', filtering: false, type: 'boolean' },
                ]
                ,
                editable: true
            },
            telemetry:
            {
                url : {
                    search: this.baseUrl + "telemetry/autocancel/telemetrylog",
                },
                tableColumns :
                [
                    {title: 'Pcc', field: 'pcc'},
                    {title: 'DateAddedUtc', field: 'dateAddedUtc', filtering: false},
                    {title: 'ExceptionMessage', field: 'exceptionMessage', filtering: false},
                ],
                editable: false
            }
        }
        this.qsorter = 
        {
            agent : 
            {
                url:
                {
                    search: this.baseUrl + "search/qsorter/agent",
                    admin: this.baseUrl + "qsorter/agent",                    
                } , 
                tableColumns : [

                    { title: 'Pcc', field: 'pcc' },
                    { title: 'Agent Queue', field: 'agentQ' },
                    { title: 'Agent SON', field: 'agentSon' },
                    { title: 'Agent Name', field: 'agentName' },
                  ],
                  editable: true
            },
            queue : 
            {
                url:
                {
                    search: this.baseUrl + "search/qsorter/queue",
                    admin: this.baseUrl + "qsorter/queue"
                } , 
                tableColumns : [
                    
                    { title: 'Pcc', field: 'pcc' },
                    { title: 'System Queue', field: 'sysQ' },
                    { title: 'Category', field: 'sortedCagegory' },
                    { title: 'Sort', field: 'doSort', type: 'boolean', filtering: false }
                  ],
                  editable: true
            }
        }
        this.gtid = {
            agency : 
            {
                url:
                {
                    search: this.baseUrl + "search/gtid/agency",
                    admin: this.baseUrl + "gtid/agency"
                } , 
                tableColumns : [            
                { title: 'Pcc', field: 'pcc' , editable: 'onAdd', debounced: true},
                { title: 'Agency Time zone City', field: 'agencyTimeZoneCity' },
                { title: 'Agent nick name', field: 'agencyNickName', debounced: true },
                { title: 'Account manager email', field: 'accountManagerEmail' },
                ]
                ,
                editable: true
            },
            gtid : 
            {
                url:
                {
                    search: this.baseUrl + "search/gtid/gtid",
                    admin: this.baseUrl + "gtid/gtid"  
                },
                tableColumns : [            
                { title: 'Pcc', field: 'owningPcc', editable: 'onAdd'},
                { title: 'Gtid', field: 'gtid' , editable: 'onAdd'},
                { title: 'Linkage type', field: 'gtidLinkageType', editable: 'onAdd' },
                ],
                editable: true
            }
        }
        this.bulk = this.baseUrl + "bulk"        
    }
}