import React from "react";

const Home = () => {
    return (
        <div>
        <h2>Welcome to Travelport's Automation Admin Portal</h2>
        <div>
            <h3>Below mentioned solutions can be configured using this Portal</h3>
            <ul>
                <li> QSorter</li>
                <li>GTID</li>
                <li>Auto Cancel</li>
            </ul>
        </div>
        </div>
    )
};

export default Home;