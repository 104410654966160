import React, { Component } from "react";
import CustomComponent from "./CustomComponent";
import {  Button, Input, FormControl, Badge } from "@atlas-design-system/react"; //DatePicker
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'; //KeyboardDatePicker
import DateFnsUtils from '@date-io/date-fns';

class LogComponent extends Component {
  state = {
    searched: false,
    fromDate: new Date(),
    toDate: new Date(),
    recordlocator: "",
    searchParameter: []
  };

  handleFromDateChange = (date) => {
    this.setState({
      searched: false,
      fromDate: date
    });
  }

  handleToDateChange = (date) => {
    this.setState({
      toDate: date,
      searched: false
    });
  }

  handleTextChange = (event) => {
    this.setState({
      searched: false,
      recordlocator: event.target.value
    });
  }

  resetSearchedState = () => {
    this.setState({ searched: false });
  }

  buttonClicked = () => {
    var fromdate = new Date(this.state.fromDate);
    var todate = new Date(this.state.toDate);
    this.setState((state) => ({
      searchParameter: [
        {
          field: "fromDate",
          value: fromdate.toISOString().slice(0, 10).replace(/-/g, "")
        },
        {
          field: "toDate",
          value: todate.toISOString().slice(0, 10).replace(/-/g, "")
        },
        {
          field: "recordlocator",
          value: this.state.recordlocator
        }
      ],
      searched: true
    }));
  };

  componentDidMount() {
    let fd = new Date();
    fd.setDate(fd.getDate() - 7);
    this.setState(() => ({ fromDate: fd }));
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            flexFlow: "wrap"
          }}>
            <FormControl
              label="Record locator"
              tooltipContent="If Record locator has been specified, From and To date fields will be ignored"
            >
              <Input
                defaultValue={this.state.recordlocator}
                onChange={this.handleTextChange}
              />
            </FormControl>
            <Badge style={{ display: "flex", marginLeft: 5, marginRight: 5, alignSelf: "center", marginTop: 25 }} text="Or" />
            <FormControl className="formDatePicker" label="From date:">s
                <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd-MMM-yyyy"
                id="date-picker-from"
                value={this.state.fromDate}
                onChange={this.handleFromDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputProps={{ style: { border: 0, width: 90 } }}
              />
            </FormControl>
            <FormControl className="formDatePicker" label="To date:">
                <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                id="date-picker-to"
                variant="inline"
                format="dd-MMM-yyyy"
                value={this.state.toDate}
                onChange={this.handleToDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputProps={{ style: { border: 0, width: 90 } }}
              />
            </FormControl>
            <div style={{ display: "flex" }} >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.buttonClicked}
                style={{ "alignSelf": "flex-end", height: 37 }}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
        <CustomComponent
          resetSearchedState={this.resetSearchedState}
          searched={this.state.searched}
          searchParameter={this.state.searchParameter}
          controlTag={this.props.controlTag}
        ></CustomComponent>
      </MuiPickersUtilsProvider>
    );
  }
}
export default LogComponent;
