import React from 'react'
import { CustomComponentStructure } from '../utils/CustomComponentStructure';
import  CustomMaterialTable  from './controls/CustomMaterialTable';

class CustomComponent extends React.Component
{

    controlTag ;
    componentStructure;
    tableColumns;
    urlHolder;
    editable;
    noUpdate;
    noDelete;
    actions;

    constructor(props)
    {
        super(props);
        this.tableColumns = [];

        this.componentStructure = new CustomComponentStructure();

        const project = props.controlTag.split(".")[0];
        const entity = props.controlTag.split(".")[1];
        const structure = this.componentStructure[project][entity];
        this.tableColumns = structure.tableColumns;
        this.urlHolder = structure.url;
        this.editable = structure.editable;
        this.noUpdate = false;
        this.noDelete = false;
        this.actions = structure.actions;
        
        if (props.controlTag === "gtid.gtid")
            this.noUpdate = true;
        if (props.controlTag === "raf.consolidator")
            this.noDelete = true;

        
        
    }

    componentDidUpdate(prevProps)
    {
    }
    componentDidMount()
    {
    }
    render()
    {


        return <CustomMaterialTable 
            searchParameter={this.props.searchParameter}
            urlHolder={this.urlHolder} 
            resetSearchedState = {this.props.resetSearchedState} 
            editable= {this.editable} 
            noUpdate={this.noUpdate} 
            noDelete = {this.noDelete}
            searched = {this.props.searched} 
            controlTag={this.props.controlTag}
            tableTitle={this.props.title}
            tableColumns={this.tableColumns}
            actions={this.actions}
                 /> 
    }
}
export default CustomComponent;