import React from "react";
import CustomComponent from "./CustomComponent";
import CustomMaterialTable from './controls/CustomMaterialTable';
import { Button, FormControl } from "@atlas-design-system/react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import FetchService from '../utils/FetchService';
import DateFnsUtils from '@date-io/date-fns'; //this library of specific version 1.3.13 is required

class RafStatisticsComponent extends React.Component
{
    state = {
        searched: false,
        searchParameter: [],
        telemetryData: {},
        dialogHidden: true,
        searchDate: new Date() //initial value cannot be undefined to avoid the "choose the component to be controlled or uncontrolled" error
    };

    resetSearchedState = () => {
        this.setState({ searched: false });
    }

    setDateFilter(date) {
        //let element = document.querySelector('[aria-label="filter data by DateAddedUtc"]');
        //element.value = date;
        //element.setAttribute("value", date);

        this.setState({
            searchParameter: [
                {
                    field: "date",
                    value: date
                }
            ],
            searched: true,
            searchDate: date
        });
    }

    searchDatepickerHandler(date) {
        this.setState({
            searchDate: date
        });
    }

    disablePastDates(date) {
        return date < new Date().setMonth(new Date().getMonth() - 1);
    }

    searchDateButtonClicked() {
        this.setState({
            searchParameter: [
                {
                    field: "date",
                    value: this.dateToStringFormat(this.state.searchDate)
                }
            ],
            searched: true
        });
    }

    dateToStringFormat(date) {
        //to format yyyy-MM-dd
        let monthNumber = new Date(date).getMonth() + 1;
        let dateNumber = new Date(date).getDate();

        return new Date(date).getFullYear().toString() + "-" +
            (monthNumber < 10 ? "0" + monthNumber : monthNumber).toString() + "-" +
            (dateNumber < 10 ? "0" + dateNumber : dateNumber).toString();
    }

    fetchLogs() {
        let telemetryApiUrl = process.env.REACT_APP_SERVICE_BASE_URL + "telemetry/raf/statistics/0/max";
        let options = {
            method: 'POST',
            headers: {
                'Accept-Encoding': 'gzip'
            }
        }

        FetchService(telemetryApiUrl, options)
            .then(response => response.json())
            .then(response => this.setState({
                telemetryData: response,
                dialogHidden: !response.showWarning,
            }))
            .catch((error) => console.log(error));
    }

    componentDidMount() {
        this.fetchLogs();
    }

    handleDialogHiddenChange() {
        this.setState(prev => { return { dialogHidden: !prev.dialogHidden } });
    }

    render() {

        return (
            <div>
                <div style={{ "alignItems": "flex-end" }}>

                    <h3>Statistics - Installed plugin</h3>
                    <div>
                        <div>Unique Pcc number: {this.state.telemetryData.pccNumber}</div>
                        <div>Unique ClientId number: {this.state.telemetryData.clientIdNumber}</div>
                        <br />
                        <table style={{ "textAlign": "left", "position": "relative", "borderCollapse": "collapse", "backgroundColor": "#f6f6f6" }}>
                            <caption style={{ "minWidth": "220px" }}>ClientIds number per RAF version:</caption>
                            <thead>
                                <tr>
                                    <th style={{ "border": "1px solid #999", "padding": "5px" }}>Client No.</th>
                                    <th style={{ "border": "1px solid #999", "padding": "5px" }}>Version</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.telemetryData.clientIdsPerVersion?.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td style={{ "border": "1px solid #999", "padding": "5px" }}>{val.terminalCount}</td>
                                            <td style={{ "border": "1px solid #999", "padding": "5px" }}>{val.version}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="datepicker" style={{ width: "18em" }}>
                        <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl className="formDatePicker" label="">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        id="sb-date-picker"
                                        format="yyyy-MM-dd"
                                        onChange={date => this.searchDatepickerHandler(date)}
                                        onAccept={date => this.searchDatepickerHandler(date)}
                                        value={this.state.searchDate}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputProps={{ style: { border: 0, width: 90 } }}
                                        helperText=""
                                        error={false}
                                        disableFuture
                                        shouldDisableDate={date => this.disablePastDates(date)}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => this.searchDateButtonClicked()}
                                style={{ "alignSelf": "flex-end", height: 37 }}
                            >
                                Search
                            </Button>
                        </div>
                    </div>

                </div>

                <h3>Logs</h3>
                <CustomComponent
                    resetSearchedState={this.resetSearchedState}
                    searched={this.state.searched}
                    searchParameter={this.state.searchParameter}
                    controlTag={this.props.controlTag}
                ></CustomComponent>
            </div>
        );
    }
}
export default RafStatisticsComponent;