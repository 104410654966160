import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { LogLevel } from 'msal';
 
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/6416915b-6778-4c36-ba4f-e56ff64a8bb7/',
    //clientId: 'e2e0c567-e1c1-47d8-a788-e28377c452d0',
    clientId: 'e6127754-94c7-4d4c-8cf7-550907bba463',
    redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                    return;
              }
          },
          piiLoggingEnabled: true
      }
      // windowHashTimeout: 60000,
      // iframeHashTimeout: 6000,
      // loadFrameTimeout: 0
  }
};
 
const authenticationParameters = {
  scopes: [
      /*'user.read',*/
      /*'openid',
      'profile',*/
      'https://travelport365.onmicrosoft.com/TravelportSolutionAdminPortal/Directory.AccessAsUser.All',
      'https://travelport365.onmicrosoft.com/TravelportSolutionAdminPortal/user.read',
      'user.read',
      'openid',
      'profile'
    
    
/*    
    'https://travelport365.onmicrosoft.com/LTDMicroService/Directory.AccessAsUser.All',
    'https://travelport365.onmicrosoft.com/LTDMicroService/user.read',
  */  
    //'user.read',
    //'https://travelport365.onmicrosoft.com/HangfireDashboard//user_impersonation'
    //'https://<your-tenant-name>.onmicrosoft.com/<your-application-name>/<scope (i.e. demo.read)>'
  ] 
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)